<template>
  <div v-if="appointment">
    <v-card class="card-shadow border-radius-xl pb-4">
      <v-toolbar elevation="0">
        <v-btn class="bg-light" elevation="0" @click="$emit('close')">
          <v-icon size="15" class="">fas fa-times</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <div class="font-weight-bolder text-xl">Terminübersicht</div>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn
          v-if="
            selectedType == 'provider' ||
            appointment.creatorId == userInformations.id
          "
          class="bg-danger text-white ml-2"
          elevation="0"
          @click="showWarningAlert()"
        >
          <v-icon size="15" class="">fas fa-trash</v-icon>
        </v-btn>
        <div v-else style="width: 64px"></div>
      </v-toolbar>
      <v-divider></v-divider>
      <div v-if="project">
        <v-row class="justify-center pt-3 pb-5 px-9">
          <div class="text-center">
            <v-avatar
              size="150"
              class="ma-3 text-uppercase text-dark bg-gradient-warning"
            >
              <v-img
                :src="getImage(project.imagePath)"
                :alt="project.title"
                v-on:error="onImgError"
              />
            </v-avatar>
            <h5 class="text-h5 mt-2 font-weight-bold text-typo">
              {{ project.title }}
            </h5>
            <div
              class="mb-1 font-weight-normal text-md"
              v-if="project.serviceProvider"
            >
              {{ project.serviceProvider.name }}
              {{ project.serviceProvider.legalForm }}
            </div>
          </div>
        </v-row>
      </div>
      <v-list class="pa-0" v-if="appointment">
        <v-divider></v-divider>

        <div>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bolder text-md"
                >Termin Start</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <div class="mr-3 pt-1 secondary--text text-md">
                <span v-if="appointment.rawDate"
                  >{{ appointment.rawDate | moment("DD.MM.YYYY - HH:mm") }}
                </span>
              </div>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bolder text-md"
                >Termin Ende</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <div class="mr-3 pt-1 secondary--text text-md">
                <span v-if="appointment.rawDateEnd"
                  >{{ appointment.rawDateEnd | moment("DD.MM.YYYY - HH:mm") }}
                </span>
              </div>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-if="project">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bolder text-md"
                >Ansprechpartner</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <div class="mr-3 pt-1 secondary--text text-md">
                <span v-if="project.manager"
                  >{{
                    `${project.manager.firstname || ""} ${
                      project.manager.lastname || ""
                    }`
                  }}
                </span>
              </div>
            </v-list-item-icon>
          </v-list-item>
          <v-divider v-if="project"></v-divider>
        </div>
        <div>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bolder text-md"
                >Bestätigung</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-chip v-if="appointment.accepted == null" class="bg-light">
                Ausstehend
              </v-chip>
              <v-chip
                v-else-if="appointment.accepted == true"
                class="bg-success text-white"
              >
                Bestätigt
              </v-chip>
              <v-chip v-else class="bg-danger text-white"> Abgelehnt </v-chip>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
        </div>
      </v-list>
      <div class="px-4 pt-4">
        <span class="font-weight-bolder">Anlass des Termins</span>
        <div>
          <span>{{ appointment.description }}</span>
        </div>
      </div>
      <div class="px-5 text-center mt-5">
        <span class="font-weight-bolder"> Erstellt: </span>
        <span>
          {{ appointment.createdAt | moment("DD.MM.YYYY - HH:mm") }} Uhr
        </span>
      </div>
    </v-card>
  </div>
</template>
    
<script>
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "AppointmentInfoModal",
  props: {
    appointment: null,
    project: null,
  },
  data() {
    return {};
  },

  methods: {
    showWarningAlert() {
      this.$swal({
        title: "Bist du dir sicher, dass du den Termin löschen möchtest?",
        text: "Du kannst dies nicht rückgängig machen!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Ja, bitte löschen!",
        cancelButtonText: "Nein, abbrechen!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$store.commit("loadingCustomers", true);
          axiosAuth
            .delete("/appointments/" + this.appointment.id, {
              headers: {
                version: "v1.1",
              },
            })
            .then((res) => {
              this.$store.commit("loadingAppointments", false);
              this.$store.dispatch("getProjects", this.selectedCompany.id);
              if (this.selectedCompany)
                this.$store.dispatch(
                  "getSelectedCompany",
                  this.selectedCompany.id
                );
              if (this.selectedProject)
                this.$store.dispatch(
                  "getSelectedProject",
                  this.selectedProject.id
                );
              this.$swal.fire(
                "Gelöscht!",
                "Der Termin wurde gelöscht.",
                "success"
              );
              this.$emit("close", true);
            })
            .catch((error) => {
              console.log(error);

              this.$store.commit("loadingAppointments", false);
              this.$store.dispatch("getProjects", this.selectedCompany.id);
              this.$swal.fire(
                "Fehler bei der Anfrage!",
                "Der Termin konnte nicht gelöscht werden.",
                "error"
              );
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire("Löschen abgebrochen", "", "error");
        }
      });
    },
  },
  computed: {},
};
</script>