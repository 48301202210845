<template>
  <div>
    <v-row class="fill-height">
      <v-col class="border-radius-xl bg-white">
        <v-sheet height="64" class="border-radius-xl">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Heute
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> fas fa-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> fas fa-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> fas fa-chevron-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Tag</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Woche</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Monat</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            locale="de-DE"
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>
          <!-- <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu> -->
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="appointmentOpen" max-width="500px" persistent>
      <appointment-info
        :appointment="selectedEvent.appointment"
        :project="selectedEvent.project"
        @close="appointmentOpen = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import Tasks from "@/components/Cards/Tasks.vue";
import AppointmentInfo from "@/components/Modals/AppointmentInfo.vue";
export default {
  components: { Tasks, AppointmentInfo },
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Monat",
      week: "Woche",
      day: "Tag",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    appointmentOpen: false,
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
  }),
  computed: {
    events() {
      let array = [];

      if (this.selectedType == "provider") {
        if (this.appointments) {
          this.appointments.forEach((appointment) => {
            if (
              (appointment.accepted == "1" ||
                appointment.sentByProvider == "1") &&
              !appointment.project
            ) {
              let newAppointment = {
                name: appointment.description,
                start: new Date(appointment.rawDate),
                end: new Date(appointment.rawDateEnd),
                color:
                  appointment.accepted == "1"
                    ? "green"
                    : appointment.accepted == "0"
                    ? "red"
                    : "#edd680",
                timed: true,
                appointment: appointment,
              };
              array.push(newAppointment);
            }
          });
        }
        if (this.projects && this.projects.data) {
          this.projects.data.forEach((project) => {
            project.appointments.forEach((appointment) => {
              if (
                appointment.accepted == "1" ||
                appointment.sentByProvider == "1"
              ) {
                let newAppointment = {
                  name: appointment.description,
                  start: new Date(appointment.rawDate),
                  end: new Date(appointment.rawDateEnd),
                  color:
                    appointment.accepted == "1"
                      ? "green"
                      : appointment.accepted == "0"
                      ? "red"
                      : "#edd680",
                  timed: true,
                  appointment: appointment,
                  project: project,
                };
                array.push(newAppointment);
              }
            });
            project.tasks.forEach((task) => {
              if (task.deadline) {
                let newAppointment = {
                  name: task.description,
                  start: new Date(task.deadline),
                  end: new Date(task.deadline),
                  color: task.color ? "#" + task.color : "black",
                  timed: true,
                  task: task,
                  project: project,
                };

                array.push(newAppointment);
              }
            });
          });
        }
      } else {
        if (this.selectedProject) {
          this.selectedProject.appointments.forEach((appointment) => {
            if (
              appointment.accepted == "1" ||
              appointment.sentByCustomer == "1"
            ) {
              let newAppointment = {
                name: appointment.description,
                start: new Date(appointment.rawDate),
                end: new Date(appointment.rawDateEnd),
                color:
                  appointment.accepted == "1"
                    ? "green"
                    : appointment.accepted == "0"
                    ? "red"
                    : "#edd680",
                timed: true,
                appointment: appointment,
                project: this.selectedProject,
              };
              array.push(newAppointment);
            }
          });
        }
      }

      return array;
    },
  },
  methods: {
    getEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      this.events = events;
    },
    getEventColor(event) {
      return event.color;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      if (event.appointment) {
        const open = () => {
          this.selectedEvent = event;
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.appointmentOpen = true))
          );
        };

        if (this.appointmentOpen) {
          this.appointmentOpen = false;
          requestAnimationFrame(() => requestAnimationFrame(() => open()));
        } else {
          open();
        }

        nativeEvent.stopPropagation();
      } else if (event.task) {
        this.selectTask(event.task);
      }
    },
    updateRange({ start, end }) {},
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>