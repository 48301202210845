<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <calendar-widget></calendar-widget>
    </v-container>
  </div>
</template>

<script>
import CalendarWidget from "./Widgets/CalendarWidget.vue";
export default {
  name: "Dashboard",
  components: { CalendarWidget },
  data() {
    return {};
  },
};
</script>
